interface RouteQueryBoardV2 {
  md?: 'n' | 'p',
  op?: 'e' | 'v' | 's',
  pb?: string,
  pw?: string,
  pt?: 'VCT' | 'VCF',
  tr?: 'b' | 'w',
  mv?: string,
  mk?: string,
  an?: string,
  tl?: string,
  bp?: string,
  wp?: string,
  gr?: string,
}

export const parseUrlBoardV2 = (query: any): BoardState | null => {
  const { md, op, mv, mk, an, tr, pt, tl, bp, wp, gr, pb, pw } = query as RouteQueryBoardV2
  const boardState: BoardState = deepCopy<BoardState>(defaultBoardState)

  if (md) { boardState.type = parseMD(md) }
  if (op) { boardState.mode = parseOP(op) }
  if (tr) { boardState.puzzle.turn = parseTR(tr) }
  if (pt) { boardState.puzzle.type = parsePT(pt) }
  if (pb) { parsePX(pb, 'black', boardState.moves) }
  if (pw) { parsePX(pw, 'white', boardState.moves) }
  if (mv) { parseMV(mv, boardState.moves) }
  if (mk) { parseMK(mk, boardState.moves) }
  if (an) {
    boardState.branch.enable = true
    boardState.branch.startNumber = parseInt(an, 10)
  }
  if (tl) { boardState.game.title = decodeURIComponent(tl) }
  if (bp) { boardState.game.playerA.name = decodeURIComponent(bp) }
  if (wp) { boardState.game.playerB.name = decodeURIComponent(wp) }
  if (gr) {
    const { endNumber, result } = parseGR(gr)
    boardState.game.endNumber = endNumber
    boardState.game.result = result
  }
  boardState.history = boardState.moves.map(move => ({ ...move }))

  return boardState
}

const parseMD = (md: string): BoardType => {
  switch (md) {
    case 'n': return 'game'
    case 'p': return 'puzzle'
    default: return 'game'
  }
}

const parseOP = (op: string): BoardMode => {
  switch (op) {
    case 'e': return 'edit'
    case 'v': return 'view'
    case 's': return 'view'
    default: return 'view'
  }
}

const parseTR = (tr: string): TurnType => {
  switch (tr) {
    case 'b': return 'black'
    case 'w': return 'white'
    default: return '-'
  }
}

const parsePT = (pt: string): PuzzleType => {
  switch (pt) {
    case 'VCT': return 'vct'
    case 'VCF': return 'vcf'
    default: return '-'
  }
}

const parseMV = (mv: string, moves: MoveProps[]) => {
  const positionStrings = mv.match(/.{2}/g)

  if (positionStrings === null) { return [] }

  positionStrings.forEach((positionString) => {
    const parsedX = parseInt(positionString[0], 15)
    const parsedY = parseInt(positionString[1], 15)
    if (
      isNaN(parsedX) || isNaN(parsedY) ||
      parsedX < 0 || parsedX > 15 ||
      parsedY < 0 || parsedY > 15
    ) { return }
    moves.push({
      x: parsedX,
      y: parsedY,
      color: moves.length % 2 === 0 ? 'white' : 'black',
      number: moves.length,
      marks: [],
      markStrings: defaultMarkStrings
    })
    return false
  })
}

const parseMK = (mk: string, moves: MoveProps[]) => {
  const decodedMarkString = decodeURIComponent(mk)
  const splitMarkStrings = decodedMarkString.split('-')
  if (splitMarkStrings.length === 0) { return }
  splitMarkStrings.forEach((markString) => {
    if (markString.length < 4) { return }
    const number = parseInt(markString.slice(0, 2), 16)
    const marks = markString.slice(2, markString.length).match(/.{3}/g)
    const foundMove = moves.find(move => move.number === number)
    if (foundMove === undefined) { return }
    marks?.forEach((mark) => {
      const parsedX = parseInt(mark[0], 16)
      const parsedY = parseInt(mark[1], 16)
      if (
        isNaN(parsedX) || isNaN(parsedY) ||
        parsedX < 0 || parsedX > 15 ||
        parsedY < 0 || parsedY > 15
      ) { return }
      foundMove?.marks?.push({
        x: parsedX,
        y: parsedY,
        mark: mark[2]
      })
    })
  })
}

const parsePX = (px: string, color: StoneColor, moves: MoveProps[]) => {
  const stoneStrings = px.match(/.{2}/g)
  if (stoneStrings === null) { return }
  stoneStrings.forEach((stoneStrings) => {
    const parsedX = parseInt(stoneStrings[0], 15)
    const parsedY = parseInt(stoneStrings[1], 15)
    if (
      isNaN(parsedX) || isNaN(parsedY) ||
      parsedX < 0 || parsedX > 15 ||
      parsedY < 0 || parsedY > 15
    ) { return }
    const foundMove = moves.find(move => move.x === parsedX && move.y === parsedY)
    if (foundMove) { return }
    moves.push({
      x: parsedX,
      y: parsedY,
      color,
      marks: [],
      markStrings: defaultMarkStrings
    })
  })
}

const parseGR = (gr: string): { endNumber: GameEndNumber, result: GameResult } => {
  const splitGameResult = gr.split('-')
  if (splitGameResult.length !== 3) { return { endNumber: '-', result: '-' } }

  const resultPropsList: { string: string, value: GameResult }[] = [
    { string: 'BlackWin', value: 'resign' },
    { string: 'WhiteWin', value: 'resign' },
    { string: 'Draw', value: 'draw' }
  ]
  const foundResultProps = resultPropsList.find(resultProps => resultProps.string === splitGameResult[2])

  return {
    endNumber: `${splitGameResult[0]}-${splitGameResult[1]}`,
    result: foundResultProps ? foundResultProps?.value : '-'
  }
}

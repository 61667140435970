export const parseBoardData = (boardData: string, site: RenjuSiteType): Partial<BoardState> | null => {
  switch (site) {
    case 'renju_net': return parseBoardDataRenjuNet(boardData)
    case 'renju_note': return parseBoardDataRenjuNote(boardData)
    case 'gomoku_quest': return parseBoardDataGomokuQuest(boardData)
    default: return null
  }
}

const parseBoardDataRenjuNet = (boardData: string): Partial<BoardState> | null => {
  const matchMoveStrings = boardData.match(/([a-o]{1})([0-9]{1,2})/g)
  if (!matchMoveStrings) { return null }

  const moves: MoveProps[] = []
  if (matchMoveStrings?.some((moveString) => {
    const x = parseInt(moveString[0], 36) - 10
    const y = 15 - parseInt(moveString.slice(1, moveString.length), 10)
    if (x < 0 || x > 14 || y < 0 || y > 14) { return true }
    moves.push({
      x,
      y,
      number: moves.length + 1,
      color: (moves.length) % 2 === 0 ? 'black' : 'white',
      marks: [],
      markStrings: defaultMarkStrings
    })
    return false
  }) || moves.length === 0) {
    return null
  }

  return {
    moves
  }
}

const parseBoardDataRenjuNote = (boardData: string): Partial<BoardState> | null => {
  const splitMoveStrings = boardData.split(',')
  if (!splitMoveStrings || splitMoveStrings.length === 0) { return null }

  const moves: MoveProps[] = []
  if (splitMoveStrings.some((moveString) => {
    const x = parseInt(moveString[0], 36) - 10
    const y = 15 - parseInt(moveString.slice(1, moveString.length), 10)
    if (x < 0 || x > 14 || y < 0 || y > 14) { return true }
    moves.push({
      x,
      y,
      number: moves.length + 1,
      color: (moves.length) % 2 === 0 ? 'black' : 'white',
      marks: [],
      markStrings: defaultMarkStrings
    })
    return false
  }) || moves.length === 0) {
    return null
  }

  return {
    moves
  }
}

const parseBoardDataGomokuQuest = (boardData: string): Partial<BoardState> | null => {
  const newBoardState: Partial<BoardState> = {}
  const playerBlack = boardData.match(/PB\[([^\]]*)\]/)
  const playerWhite = boardData.match(/PW\[([^\]]*)\]/)
  newBoardState.game = deepCopy(defaultBoardState.game)
  if (playerBlack && playerWhite && playerBlack.length === 2 && playerWhite.length === 2) {
    newBoardState.game = {
      ...deepCopy(defaultBoardState.game),
      title: 'Gomoku Quest',
      playerA: {
        name: playerBlack[1],
        englishName: '',
        color: 'black'
      },
      playerB: {
        name: playerWhite[1],
        englishName: '',
        color: 'white'
      }
    }

    if (boardData.match(/;QPR/)) {
      newBoardState.game.openingRule = 'swap_1'
    } else if (boardData.match(/;QSLB/) || boardData.match(/;QSLW/)) {
      newBoardState.game.openingRule = 'taraguchi_10'
    } else {
      newBoardState.game.openingRule = '-'
    }
  }

  newBoardState.moves = [{ ...deepCopy(defaultBoardState.moves[0]) }]

  // 珠型提示
  const matchOpeningStrings = boardData.match(/;QPR\[([^\]]{2}),([^\]]{2}),([^\]]{2})\]/)
  if (matchOpeningStrings && matchOpeningStrings.length === 4) {
    matchOpeningStrings.forEach((move, index) => {
      if (index === 0) { return }
      newBoardState.moves?.push({
        x: parseInt(move[0], 36) - 10,
        y: parseInt(move[1], 36) - 10,
        number: newBoardState.moves.length,
        color: (newBoardState.moves.length) % 2 === 1 ? 'black' : 'white',
        marks: [],
        markStrings: defaultMarkStrings
      })
    })
  }

  if (newBoardState.game.openingRule === 'swap_1' && boardData.split(';').some((moveString) => { return moveString.match(/^QSLW/) })) {
    boardData = boardData.replace(/;QSLW/, ';W')
  }
  const matchMoveStrings = boardData.match(/;[BW]{1}\[[^\]]*\]/g)
  if (!matchMoveStrings) { return null }
  if (matchMoveStrings?.some((moveString) => {
    const matchMoveParams = moveString.match(/;([BW]{1})\[([^\]]{2})\]/)
    if (!matchMoveParams || matchMoveParams.length !== 3) { return true }
    const color: StoneColor = matchMoveParams[1] === 'B' ? 'black' : 'white'
    const x = parseInt(matchMoveParams[2][0], 36) - 10
    const y = parseInt(matchMoveParams[2][1], 36) - 10
    if (x < 0 || x > 14 || y < 0 || y > 14) { return true }
    newBoardState.moves?.push({
      x,
      y,
      number: newBoardState.moves.length,
      color,
      marks: [],
      markStrings: defaultMarkStrings
    })
    return false
  }) || newBoardState.moves.length === 0) {
    return null
  }

  if (newBoardState.game?.openingRule === 'taraguchi_10') {
    const splittedBoardData = boardData.split(';')
    let moveNumber = 0
    const swapData = [0, 0, 0, 0]
    splittedBoardData.forEach((moveString) => {
      if (moveString.match(/^W|^B/)) {
        moveNumber++
      } else if (moveString.match(/^QSLB|^QSLW/)) {
        swapData[moveNumber - 1] = 1
      }
    })
    swapData.forEach((swap, index) => {
      if (newBoardState.moves && newBoardState.moves.length >= (1 + index * 2)) {
        newBoardState.moves.splice(2 + index * 2, 0, { swap: Boolean(swap) })
      }
    })
  } else if (newBoardState.game?.openingRule === 'swap_1') {
    const splittedBoardData = boardData.split(';')
    newBoardState.moves.splice(4, 0, { swap: splittedBoardData.some((moveString) => { return moveString.match(/^QSLB/) }) })
  }

  const matchedResultString = boardData.match(/RE\[([^\]]*)\]/)
  if (matchedResultString?.length === 2) {
    const splittedResult = matchedResultString[1].split('+')
    if (splittedResult[0] === 'Draw') {
      newBoardState.game.result = 'draw'
    } else if (splittedResult[1] === 'R') {
      newBoardState.game.result = 'resign'
    } else if (splittedResult[1] === 'T') {
      newBoardState.game.result = 'timeout'
    } else {
      newBoardState.game.result = 'five'
    }
    const fileterdMoves = newBoardState.moves.filter(move => move?.number)
    const lastMove = fileterdMoves[fileterdMoves.length - 1]
    newBoardState.game.endNumber = lastMove.color + '-' + lastMove.number
  }

  return newBoardState
}

import { RouteQueryBoardV3 } from 'utils/urlBoard/urlBoardParserV3'
import { InputModeControlProps, OpeningRuleController } from '.'

export const freeController: OpeningRuleController = {
  getGuideText (moves: MoveProps[]): null | string {
    switch (moves.length) {
      default:
        return null
    }
  },

  getInputModeType (_moves: MoveProps[]): InputModeControlProps[] {
    return [
      { inputMode: 'stone', select: true },
      { inputMode: 'mark' },
      { inputMode: 'swap', visible: false }
    ]
  },

  getCurrentPlayer (moves: MoveProps[]): PlayerType {
    if (!moves || moves.length === 0 || !(moves[moves.length - 1]?.number)) { return 'first' }
    const currentNumber = moves[moves.length - 1]?.number
    if (!currentNumber) { return 'first' }
    return currentNumber % 2 === 0 ? 'first' : 'second'
  },

  validateMove (_moves: MoveProps[], _position: PositionProps): boolean {
    return true
  },

  parseUrl (_query: RouteQueryBoardV3, _moves: MoveProps[]): void {

  },

  getBranchModeDisabled (_moves: MoveProps[]): boolean {
    return false
  },

  getSwapYesLabel (_moves: MoveProps[]): string {
    return ''
  },

  getSwapNoLabel (_moves: MoveProps[]): string {
    return ''
  }
}

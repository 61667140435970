import { RouteQueryBoardV3 } from 'utils/urlBoard/urlBoardParserV3'
import { freeController } from './free'
import { soosyrv8Controller } from './soosyrv-8'
import { swap1Controller } from './swap-1'
import { taraguchi10Controller } from './taraguchi-10'

export interface InputModeControlProps {
  inputMode: InputMode,
  visible?: boolean,
  disabled?: boolean,
  select?: boolean
}

export interface OpeningRuleController {
  upperOfferNumber?: number,
  getGuideText(moves: MoveProps[]): null | string,
  getInputModeType(moves: MoveProps[]): InputModeControlProps[],
  getBranchModeDisabled(moves: MoveProps[]): boolean,
  getCurrentPlayer(moves: MoveProps[]): PlayerType
  validateMove(moves: MoveProps[], position: PositionProps): boolean
  parseUrl(query: RouteQueryBoardV3, moves: MoveProps[]) : void,
  getSwapYesLabel(moves: MoveProps[]): string,
  getSwapNoLabel(moves: MoveProps[]): string,
  isEnableOption10?(moves: MoveProps[]): boolean
}

export function getOpeningRuleController (openingRule: OpeningRule): OpeningRuleController {
  switch (openingRule) {
    case '-': return freeController
    case 'swap_1' : return swap1Controller
    case 'soosyrv_8': return soosyrv8Controller
    case 'taraguchi_10': return taraguchi10Controller
    default: return freeController
  }
}

export function getGuideText (openingRule: OpeningRule, moves: MoveProps[]): null | string {
  return getOpeningRuleController(openingRule).getGuideText(moves)
}

export function getInputModeVisible (openingRule: OpeningRule, moves: MoveProps[], InputMode: InputMode): boolean {
  const inputModeProps = getOpeningRuleController(openingRule).getInputModeType(moves).find(item => item.inputMode === InputMode)
  return inputModeProps?.visible ?? true
}

export function getInputModeDisabled (openingRule: OpeningRule, moves: MoveProps[], InputMode: InputMode): boolean {
  const inputModeProps = getOpeningRuleController(openingRule).getInputModeType(moves).find(item => item.inputMode === InputMode)
  return inputModeProps?.disabled ?? false
}

export function getBranchModeDisabled (openingRule: OpeningRule, moves: MoveProps[]): boolean {
  return getOpeningRuleController(openingRule).getBranchModeDisabled(moves)
}

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string,
    auto?: boolean,
    square?: boolean
  }>(),
  {
    name: 'name',
    auto: false
  }
)

const { moves, board, branch } = useBoard(props.name)

const emit = defineEmits<{(e: 'board:click', position: { x: number, y: number }): void, (e: 'board:right-click'): void}>()

const svg = ref<HTMLElement | null>(null)

const computedLineProps = computed<LineProps[]>(() => {
  const lineProps: LineProps[] = []

  for (let x = 0; x < board.value.numberOfXLine; x++) {
    lineProps.push({
      x1: board.value.boxSize * (x + 1.5),
      y1: board.value.boxSize * 1.5,
      x2: board.value.boxSize * (x + 1.5),
      y2: board.value.boxSize * (board.value.numberOfYLine + 0.5)
    })
  }

  for (let y = 0; y < board.value.numberOfYLine; y++) {
    lineProps.push({
      x1: board.value.boxSize * 1.5,
      y1: board.value.boxSize * (y + 1.5),
      x2: board.value.boxSize * (board.value.numberOfXLine + 0.5),
      y2: board.value.boxSize * (y + 1.5)
    })
  }

  return lineProps
})

const computedOfferMoves = computed<{ x: number, y: number, color: StoneColor }[]>(() => {
  const offerNumber = moves.value.find(move => move.offerNumber)?.offerNumber
  if (!offerNumber) { return [] }
  const usedOfferMoves = moves.value.find(move => move.offerMoveMarks && move.offerMoveMarks.length > 0)?.offerMoveMarks?.length || 0
  return Array(offerNumber - usedOfferMoves).fill(0).map((_, index) => ({ x: 15, y: 14 - index, color: 'black' }))
})

const computedViewBox = computed<string>(() => {
  return `0 0 ${board.value.boxSize * (board.value.numberOfXLine + 2)} ${board.value.boxSize * (board.value.numberOfYLine + 2)}`
})

const computedMaxWidth = computed<string>(() => {
  return props.auto ? 'unset' : `${board.value.boxSize * (board.value.numberOfXLine + 2)}`
})

const handleClickBoard = (event: MouseEvent) => {
  const clientRect = svg?.value?.getBoundingClientRect()
  if (!clientRect || !svg.value) { return }

  const clickX = event.pageX
  const clickY = event.pageY
  const positionX = clientRect.left + window.pageXOffset
  const positionY = clientRect.top + window.pageYOffset
  const ratio = svg.value?.clientWidth / (board.value.boxSize * (board.value.numberOfXLine + 2))
  const x = Math.floor((clickX - positionX - board.value.boxSize * ratio) / (board.value.boxSize * ratio))
  const y = Math.floor((clickY - positionY - board.value.boxSize * ratio) / (board.value.boxSize * ratio))

  if (x < 0 || y < 0 || x >= board.value.numberOfXLine || y >= board.value.numberOfYLine) { return }

  emit('board:click', { x, y })
}

const handleRightClickBoard = (_event: MouseEvent) => {
  emit('board:right-click')
}
</script>

<template>
  <div :id="props.name" :style="{ backgroundColor: board.color.background }">
    <svg
      ref="svg"
      class="board"
      :class="{ auto: auto }"
      :style="{ maxWidth: computedMaxWidth }"
      :viewBox="computedViewBox"
      style="display: block; margin: auto; user-select: none;"
      :square="props.square"
      @click="handleClickBoard"
      @contextmenu.prevent="handleRightClickBoard"
    >
      <rect
        :x="0"
        :y="0"
        :width="board.boxSize * (board.numberOfXLine + 2)"
        :height="board.boxSize * (board.numberOfYLine + 2)"
        :fill="board.color.background"
      />
      <line
        v-for="(lineProps, index) in computedLineProps"
        :key="`line_${index}`"
        :x1="lineProps.x1"
        :y1="lineProps.y1"
        :x2="lineProps.x2"
        :y2="lineProps.y2"
        fill="none"
        :stroke="board.color.line"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <rect
        :x="board.boxSize * 1.5"
        :y="board.boxSize * 1.5"
        :width="board.boxSize * (board.numberOfXLine - 1)"
        :height="board.boxSize * (board.numberOfYLine - 1)"
        fill="none"
        :stroke="board.color.line"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <circle
        v-for="(starPoint, index) in board.starPositions"
        :key="`star_${index}`"
        :cx="board.boxSize * (starPoint.x + 1.5)"
        :cy="board.boxSize * (starPoint.y + 1.5)"
        :r="board.boxSize / 10"
        :fill="board.color.star"
      />
      <circle
        v-for="(move) in moves.filter(move => move.color === 'black')"
        :key="`stone_${move.number}`"
        :cx="board.boxSize * ((move.x ?? 0) + 1.5)"
        :cy="board.boxSize * ((move.y ?? 0) + 1.5)"
        :r="board.boxSize / 2.125"
        :fill="board.color.blackStoneBackground"
        stroke-width="1.5"
        :stroke="board.color.blackStoneLine"
      />
      <circle
        v-for="(move) in moves.filter(move => move.color === 'white')"
        :key="`stone_${move.number}`"
        :cx="board.boxSize * ((move.x ?? 0) + 1.5)"
        :cy="board.boxSize * ((move.y ?? 0) + 1.5)"
        :r="board.boxSize / 2.125"
        :fill="board.color.whiteStoneBackground"
        stroke-width="1.5"
        :stroke="board.color.whiteStoneLine"
      />
      <text
        v-for="blackMove in moves.filter(move => {
          return move.color === 'black' &&
            move.number &&
            board.visible.number &&
            (!branch.enable || (move?.number || 0) > (branch?.startNumber || 0))
        })"
        :key="`stone_${blackMove.number}_text`"
        :fill="board.color.blackStoneNumber"
        font-size="15"
        font-family="din-2014, sans-serif"
        :x="board.boxSize * ((blackMove.x ?? 0) + 1.5)"
        :y="board.boxSize * ((blackMove.y ?? 0) + 1.5)"
        text-anchor="middle"
        dominant-baseline="central"
      >
        {{ (blackMove?.number || 0) - (branch.startNumber || 0) }}
      </text>
      <text
        v-for="whiteMove in moves.filter(move => {
          return move.color === 'white' &&
            move.number &&
            board.visible.number &&
            (!branch.enable || (move?.number || 0) > (branch?.startNumber || 0))
        })"
        :key="`stone_${whiteMove.number}_text`"
        :fill="board.color.whiteStoneNumber"
        font-size="15"
        font-family="din-2014, sans-serif"
        :x="board.boxSize * ((whiteMove.x ?? 0) + 1.5)"
        :y="board.boxSize * ((whiteMove.y ?? 0) + 1.5)"
        text-anchor="middle"
        dominant-baseline="central"
      >
        {{ (whiteMove?.number || 0) - (branch.startNumber || 0) }}
      </text>

      <circle
        v-for="(offerMoveMark, index) in moves[moves.length - 1]?.offerMoveMarks"
        :key="`offer_stone_${index}`"
        :cx="board.boxSize * (offerMoveMark.x + 1.5)"
        :cy="board.boxSize * (offerMoveMark.y + 1.5)"
        :r="board.boxSize / 2.125"
        :fill="offerMoveMark.color === 'black' ? board.color.blackStoneBackground : board.color.whiteStoneBackground"
        opacity="0.8"
      />

      <circle
        v-for="(offerMove, index) in moves[moves.length - 1]?.offerMoves"
        :key="`offer_stone_${index}`"
        :cx="board.boxSize * (offerMove.x + 1.5)"
        :cy="board.boxSize * (offerMove.y + 1.5)"
        :r="board.boxSize / 2.125"
        :fill="offerMove.color === 'black' ? board.color.blackStoneBackground : board.color.whiteStoneBackground"
        opacity="0.8"
      />

      <text
        v-for="(number, index) in board.coordinateX.slice(0, board.numberOfXLine).filter(_coordinateX => board.visible.coordinate)"
        :key="`coordinate_x_${number}`"
        :fill="board.color.coordinate"
        font-size="15"
        font-family="din-2014, sans-serif"
        :x="board.boxSize * (index + 1.5)"
        :y="board.boxSize * (board.numberOfYLine + 1.5)"
        text-anchor="middle"
        dominant-baseline="central"
      >
        {{ number }}
      </text>
      <text
        v-for="(number, index) in board.coordinateY.slice(0, board.numberOfYLine).reverse().filter(_coordinateX => board.visible.coordinate)"
        :key="`coordinate_x_${number}`"
        :fill="board.color.coordinate"
        font-size="15"
        font-family="din-2014, sans-serif"
        :x="board.boxSize * 0.5"
        :y="board.boxSize * (index + 1.5)"
        text-anchor="middle"
        dominant-baseline="central"
      >
        {{ number }}
      </text>
      <circle
        v-for="(mark, index) in moves[moves.length - 1]?.marks"
        :key="`mark_background_${moves.length}_${index}`"
        :cx="board.boxSize * (mark.x + 1.5)"
        :cy="board.boxSize * (mark.y + 1.5)"
        :r="board.boxSize / 3"
        :fill="board.color.background"
      />
      <text
        v-for="(mark, index) in moves[moves.length - 1]?.marks"
        :key="`mark_${moves.length}_${index}`"
        :fill="board.color.mark"
        font-size="15"
        font-family="din-2014, sans-serif"
        :x="board.boxSize * (mark.x + 1.5)"
        :y="board.boxSize * (mark.y + 1.5)"
        text-anchor="middle"
        dominant-baseline="central"
      >
        {{ mark.mark }}
      </text>

      <circle
        v-for="(offerMove, index) in computedOfferMoves"
        :key="`offer_move_${index}`"
        :cx="board.boxSize * (offerMove.x + 1.25)"
        :cy="board.boxSize * (offerMove.y + 1.5)"
        :r="board.boxSize / 2.125"
        :fill="board.color.blackStoneBackground"
        opacity="0.8"
      />
    </svg>
  </div>
</template>

<style scoped lang="scss">
.board {
  max-height: calc(100vh - 184px);
  max-height: calc(100lvh - 184px);
}

.board[square="true"] {
  aspect-ratio: 1 / 1;
}

.board.auto {
  max-height: unset;
}
</style>

import { RouteQueryBoardV3 } from 'utils/urlBoard/urlBoardParserV3'
import { InputModeControlProps, OpeningRuleController } from '.'

export const soosyrv8Controller: OpeningRuleController = {
  upperOfferNumber: 8,

  getGuideText (moves: MoveProps[]): null | string {
    let offerNumber = 0
    switch (moves.length) {
      case 1:
      case 2:
      case 3:
        return '珠型提示'
      case 4:
        return '交換選択'
      case 5:
      case 6:
        return '4手目 & 題数提示'
      case 7:
        return '交換選択'
      case 8:
        offerNumber = moves[6]?.offerNumber ?? 0
        return `${offerNumber} 題提示`
      case 9:
        return '5 手目選択'
      default:
        return null
    }
  },

  getInputModeType (moves: MoveProps[]): InputModeControlProps[] {
    switch (moves.length) {
      case 4:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false, select: true },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      case 5:
        return [
          { inputMode: 'stone', select: true },
          { inputMode: 'swap', visible: false },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      case 6:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false },
          { inputMode: 'offer_number', select: true },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      case 7:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false, select: true },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      case 8:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', select: true },
          { inputMode: 'mark', disabled: true }
        ]
      case 9:
        return [
          { inputMode: 'stone', select: true },
          { inputMode: 'swap', visible: false },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      default:
        return [
          { inputMode: 'stone', select: true },
          { inputMode: 'swap', visible: false },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark' }
        ]
    }
  },

  getBranchModeDisabled (moves: MoveProps[]): boolean {
    return moves.length <= 9
  },

  getCurrentPlayer (moves: MoveProps[]): PlayerType {
    switch (moves.length) {
      case 1:
      case 2:
      case 3:
        return 'first'
      case 4:
        return 'second'
      case 5:
      case 6:
        return moves[4].swap ? 'first' : 'second'
      case 7:
        return moves[4].swap ? 'second' : 'first'
      case 8:
        return ((moves[4]?.swap ? 1 : 0) + (moves[7]?.swap ? 1 : 0)) % 2 === 0 ? 'first' : 'second'
      case 9:
        return ((moves[4]?.swap ? 1 : 0) + (moves[7]?.swap ? 1 : 0)) % 2 === 0 ? 'second' : 'first'
      default:
        return ((moves[4]?.swap ? 1 : 0) + (moves[7]?.swap ? 1 : 0) + moves.length) % 2 === 0 ? 'second' : 'first'
    }
  },

  validateMove (moves: MoveProps[], position: PositionProps): boolean {
    switch (moves.length) {
      case 1:
        return position.x === 7 && position.y === 7
      case 2:
        return position.x >= 6 && position.x <= 8 && position.y >= 6 && position.y <= 8
      case 3:
        return position.x >= 5 && position.x <= 9 && position.y >= 5 && position.y <= 9
      case 4:
        return false
      default:
        return true
    }
  },

  parseUrl (query: RouteQueryBoardV3, moves: MoveProps[]) {
    const { swp, ofn, ofm } = query

    if (swp && swp.length >= 1 && moves.length >= 4) {
      moves.splice(4, 0, { swap: swp[0] === '1' })
    }

    if (ofn && ofn[0] && moves.length >= 6) {
      moves.splice(6, 0, { offerNumber: parseInt(ofn[0], 36) })
    }

    if (swp && swp.length >= 2 && moves.length >= 7) {
      moves.splice(7, 0, { swap: swp[1] === '1' })
    }

    if (ofm && moves.length >= 8) {
      const offerMoves: OfferMoveProps[] = []
      const positionStrings = ofm.match(/.{2}/g)
      if (positionStrings !== null) {
        positionStrings.forEach((positionString) => {
          const parsedX = parseInt(positionString[0], 36)
          const parsedY = parseInt(positionString[1], 36)
          if (
            isNaN(parsedX) || isNaN(parsedY) ||
            parsedX < 0 || parsedX > 19 ||
            parsedY < 0 || parsedY > 19
          ) { return }
          offerMoves.push({
            x: parsedX,
            y: parsedY,
            color: 'black'
          })
        })
      }
      moves.splice(8, 0, { offerMoves })
      moves[7].offerMoveMarks = deepCopy(offerMoves)
    }
  },

  getSwapYesLabel (_moves: MoveProps[]): string {
    return 'はい / 選択'
  },

  getSwapNoLabel (_moves: MoveProps[]): string {
    return 'いいえ / 提示'
  }
}

import { RouteQueryBoardV3 } from 'utils/urlBoard/urlBoardParserV3'
import { InputModeControlProps, OpeningRuleController } from '.'

export const taraguchi10Controller: OpeningRuleController = {

  getGuideText (moves: MoveProps[]): null | string {
    switch (moves.length) {
      case 2:
        return '交換選択'
      case 4:
        return '交換選択'
      case 6:
        return '交換選択'
      case 8:
        return '交換選択'
      case 10:
        return moves[8]?.option10 ? '' : '交換選択'
      default:
        return null
    }
  },

  getInputModeType (moves: MoveProps[]): InputModeControlProps[] {
    switch (moves.length) {
      case 2:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false, select: true },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      case 4:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false, select: true },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      case 6:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false, select: true },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      case 8:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false, select: true },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      case 9:
        return moves[8]?.option10
          ? [
              { inputMode: 'stone', disabled: true },
              { inputMode: 'swap', visible: false },
              { inputMode: 'offer_number', visible: false },
              { inputMode: 'offer_moves', select: true },
              { inputMode: 'mark', disabled: true }
            ]
          : [
              { inputMode: 'stone', select: true },
              { inputMode: 'swap', visible: false },
              { inputMode: 'offer_number', visible: false },
              { inputMode: 'offer_moves', visible: false },
              { inputMode: 'mark' }
            ]
      case 10:
        return moves[8]?.option10
          ? [
              { inputMode: 'stone', select: true },
              { inputMode: 'swap', visible: false },
              { inputMode: 'offer_number', visible: false },
              { inputMode: 'offer_moves', visible: false },
              { inputMode: 'mark' }
            ]
          : [
              { inputMode: 'stone', disabled: true },
              { inputMode: 'swap', visible: false, select: true },
              { inputMode: 'offer_number', visible: false },
              { inputMode: 'offer_moves', visible: false },
              { inputMode: 'mark', disabled: true }
            ]
      default:
        return [
          { inputMode: 'stone', select: true },
          { inputMode: 'swap', visible: false },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark' }
        ]
    }
  },

  getBranchModeDisabled (moves: MoveProps[]): boolean {
    return moves.length <= 9
  },

  getCurrentPlayer (moves: MoveProps[]): PlayerType {
    switch (moves.length) {
      case 3:
      case 4:
        return ((moves[2]?.swap ? 1 : 0) + moves.length) % 2 === 0 ? 'first' : 'second'
      case 5:
      case 6:
        return ((moves[2]?.swap ? 1 : 0) + (moves[4]?.swap ? 1 : 0) + moves.length) % 2 === 0 ? 'second' : 'first'
      case 7:
      case 8:
        return ((moves[2]?.swap ? 1 : 0) + (moves[4]?.swap ? 1 : 0) + (moves[6]?.swap ? 1 : 0) + moves.length) % 2 === 0 ? 'first' : 'second'
      case 9:
      case 10:
        return ((moves[2]?.swap ? 1 : 0) + (moves[4]?.swap ? 1 : 0) + (moves[6]?.swap ? 1 : 0) + (moves[8]?.swap ? 1 : 0) + moves.length) % 2 === 0 ? 'second' : 'first'
      default:
        return moves[8]?.option10
          ? (((moves[2]?.swap ? 1 : 0) + (moves[4]?.swap ? 1 : 0) + (moves[6]?.swap ? 1 : 0) + (moves[8]?.swap ? 1 : 0) + (moves[8]?.option10 ? 0 : 1) + moves.length) % 2 === 0 ? 'first' : 'second')
          : (((moves[2]?.swap ? 1 : 0) + (moves[4]?.swap ? 1 : 0) + (moves[6]?.swap ? 1 : 0) + (moves[8]?.swap ? 1 : 0) + (moves[10]?.swap ? 1 : 0) + moves.length) % 2 === 0 ? 'first' : 'second')
    }
  },

  validateMove (moves: MoveProps[], position: PositionProps): boolean {
    switch (moves.length) {
      case 1:
        return position.x === 7 && position.y === 7
      case 3:
        return position.x >= 6 && position.x <= 8 && position.y >= 6 && position.y <= 8
      case 5:
        return position.x >= 5 && position.x <= 9 && position.y >= 5 && position.y <= 9
      case 7:
        return position.x >= 4 && position.x <= 10 && position.y >= 4 && position.y <= 10
      case 9:
        return position.x >= 3 && position.x <= 11 && position.y >= 3 && position.y <= 11
      default:
        return true
    }
  },

  parseUrl (query: RouteQueryBoardV3, moves: MoveProps[]) {
    const { swp, ofm } = query

    if (swp && swp.length >= 1 && moves.length >= 2) {
      moves.splice(2, 0, { swap: swp[0] === '1' })
    }

    if (swp && swp.length >= 2 && moves.length >= 4) {
      moves.splice(4, 0, { swap: swp[1] === '1' })
    }

    if (swp && swp.length >= 3 && moves.length >= 6) {
      moves.splice(6, 0, { swap: swp[1] === '1' })
    }

    if (swp && swp.length >= 4 && moves.length >= 8) {
      moves.splice(8, 0, { swap: swp[1] === '1' })
    }

    if (swp && swp.length >= 4 && moves.length >= 10) {
      moves.splice(10, 0, { swap: swp[1] === '1' })
    }

    if (ofm && moves.length >= 8) {
      const offerMoves: OfferMoveProps[] = []
      const positionStrings = ofm.match(/.{2}/g)
      if (positionStrings !== null) {
        positionStrings.forEach((positionString) => {
          const parsedX = parseInt(positionString[0], 36)
          const parsedY = parseInt(positionString[1], 36)
          if (
            isNaN(parsedX) || isNaN(parsedY) ||
            parsedX < 0 || parsedX > 19 ||
            parsedY < 0 || parsedY > 19
          ) { return }
          offerMoves.push({
            x: parsedX,
            y: parsedY,
            color: 'black'
          })
        })
      }
      moves.splice(8, 0, { option10: true, offerMoves })
    }
  },

  getSwapYesLabel (_moves: MoveProps[]): string {
    return 'はい'
  },

  getSwapNoLabel (_moves: MoveProps[]): string {
    return 'いいえ'
  },

  isEnableOption10 (moves: MoveProps[]): boolean {
    return moves.length === 8
  }
}

import { boardTypePropsList, boardModePropsList, problemTypePropsList, boardTurnPropsList } from './index'

export const makeBoardUrlQueryV3 = (boardState: BoardState, history: boolean = false) => {
  const params: any = {}
  const players = [boardState.game.playerA, boardState.game.playerB]
  const moves = history ? boardState.history : boardState.moves

  params.v = '3'

  params.bxl = boardState.board.numberOfXLine === 15 ? undefined : boardState.board.numberOfXLine
  params.byl = boardState.board.numberOfYLine === 15 ? undefined : boardState.board.numberOfYLine

  params.typ = boardTypePropsList.find(mode => mode.value === boardState.type)?.param
  params.mde = boardModePropsList.find(mode => mode.value === boardState.mode)?.param
  params.brc = boardState.branch.startNumber

  params.mvs = moves
    .filter(move => move.number !== undefined && move.number > 0)
    .map(move => (move?.x?.toString(36) || '') + (move?.y?.toString(36) || ''))
    .join('')
  params.mks = moves
    .filter(move => move.number !== undefined && move?.marks && move.marks.length > 0)
    .map(move =>
      (move.number !== undefined ? move.number.toString(36).padStart(2, '0') : '') +
      move?.marks
        ?.map(mark => mark.x.toString(36) + mark.y.toString(36) + mark.mark)
        .join('')
    )
    .join('-')
  params.gtr = boardState.game.tournament.renjuNetId || undefined
  params.gtl = boardState.game.title
  params.gdt = boardState.game.date
  params.grd = boardState.game.round
  params.gop = openingRulePropsList.find(openingRuleProps => openingRuleProps.value === boardState.game.openingRule)?.param
  params.swp = moves.filter(move => move.swap !== undefined).map(move => move.swap ? '1' : '0').join('')
  params.ofn = moves
    .filter(move => move.offerNumber)
    .map(move => move.offerNumber?.toString(36) || '')
    .join('')
  params.ofm = moves
    .filter(move => move.offerMoves)
    .map(move =>
      move.offerMoves
        ?.map(offerMove => (offerMove?.x?.toString(36) || '') + (offerMove?.y?.toString(36) || ''))
        .join('')
    )
    .filter(offerMoves => offerMoves && offerMoves?.length >= 2)
    .join('-')
  params.par = players.find(player => player.color === 'black')?.renjuNetId || ''
  params.pan = players.find(player => player.color === 'black')?.name || ''
  params.pae = players.find(player => player.color === 'black')?.englishName || ''
  params.pat = boardState.game.playerA.remainTime
  params.pbr = players.find(player => player.color === 'white')?.renjuNetId || ''
  params.pbn = players.find(player => player.color === 'white')?.name || ''
  params.pbe = players.find(player => player.color === 'white')?.englishName || ''
  params.pbt = boardState.game.playerB.remainTime
  params.gen = boardState.game.endNumber === '-' ? undefined : boardState.game.endNumber
  params.grs = boardState.game.result === '-' ? undefined : boardState.game.result

  params.pbs = moves
    .filter(move => move.number === undefined && move.color === 'black')
    .map(move => (move?.x?.toString(36) || '') + (move?.y?.toString(36) || ''))
    .join('')
  params.pws = moves
    .filter(move => move.number === undefined && move.color === 'white')
    .map(move => (move?.x?.toString(36) || '') + (move?.y?.toString(36) || ''))
    .join('')
  params.ptl = boardState.puzzle.title
  params.pty = problemTypePropsList.find(type => type.value === boardState.puzzle.type)?.param
  params.ptr = boardTurnPropsList.find(turn => turn.value === boardState.puzzle.turn)?.param

  boardState.answers.forEach((answer, index) => {
    params[`a${index}-mvs`] = answer.moves
      .map(move => (move?.x?.toString(36) || '') + (move?.y?.toString(36) || ''))
      .join('')

    params[`a${index}-mks`] = answer.moves
      .filter(move => move.number !== undefined && move?.marks && move.marks.length > 0)
      .map(move =>
        (move.number !== undefined ? move.number.toString(36).padStart(2, '0') : '') +
        move?.marks
          ?.map(mark => mark.x.toString(36) + mark.y.toString(36) + mark.mark)
          .join('')
      )
      .join('-')

    params[`a${index}-title`] = answer.title
  })

  const urlSearchParams = new URLSearchParams()
  Object
    .keys(params)
    .forEach((key) => {
      if (params[key]) {
        urlSearchParams.append(key, params[key])
      }
    })

  return urlSearchParams.toString()
}

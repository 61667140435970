import { starPositionPropsList } from '~/utils/defines'

export function useBoard (key: string, boardState?: Partial<BoardState> | null) {
  const data = useState<BoardState>(key, () => {
    return boardState
      ? {
          ...deepCopy<BoardState>(defaultBoardState),
          ...boardState
        }
      : deepCopy<BoardState>(defaultBoardState)
  })

  const addMove = (position: PositionProps) => {
    const openingController = getOpeningRuleController(data.value.game.openingRule)
    if (
      data.value.moves.some(move => move.x === position.x && move.y === position.y) ||
      (
        data.value.type === 'game' &&
        !openingController.validateMove(data.value.moves, position)
      )
    ) {
      return
    }

    const newMove: Partial<MoveProps> = {
      x: position.x,
      y: position.y,
      marks: [],
      markStrings: defaultMarkStrings
    }

    const lastMove = data.value.moves[data.value.moves.length - 1]
    const number = data.value.moves.filter(move => move.number !== undefined).slice(-1)[0]?.number
    switch (data.value.type) {
      case 'game':
        if (
          lastMove.offerMoves &&
          lastMove.offerMoves.length > 0 &&
          !lastMove.offerMoves.some(offerMove => offerMove.x === position.x && offerMove.y === position.y)
        ) {
          return
        }
        newMove.color = number !== undefined && number % 2 === 0 ? 'black' : 'white'
        newMove.number = number !== undefined ? number + 1 : 1
        break
      case 'puzzle':
        newMove.number = data.value.moves.filter(move => move.number !== undefined).length
        switch (data.value.puzzle.turn) {
          case 'black': newMove.color = newMove.number % 2 === 0 ? 'white' : 'black'; break
          case 'white': newMove.color = newMove.number % 2 === 0 ? 'black' : 'white'; break
          default: break
        }
        break
      default:
        break
    }
    data.value.moves.push(newMove as MoveProps)

    updateHistory()
    updateInputMode()
  }

  const addStone = (position: PositionProps) => {
    switch (data.value.inputMode) {
      case 'stone_black':
      case 'stone_white': {
        const color = data.value.inputMode === 'stone_black' ? 'black' : 'white'
        const foundMove = data.value.moves.find(move => move.x === position.x && move.y === position.y)
        if (foundMove) {
          data.value.moves = data.value.moves.filter(move => move.x !== position.x || move.y !== position.y)
          data.value.history = data.value.moves.map(move => ({ ...move }))

          if (foundMove.color === color) {
            return
          }
        }

        data.value.moves.push({
          x: position.x,
          y: position.y,
          color,
          marks: [],
          markStrings: defaultMarkStrings
        })
        data.value.history = data.value.moves.map(move => ({ ...move }))
        break
      }
      case 'stone_alternate': {
        if (data.value.moves.some(move => move.x === position.x && move.y === position.y)) {
          return
        }
        data.value.moves.push({
          x: position.x,
          y: position.y,
          color: data.value.alternateStoneColor,
          marks: [],
          markStrings: defaultMarkStrings
        })
        data.value.history = data.value.moves.map(move => ({ ...move }))
        data.value.alternateStoneColor = data.value.alternateStoneColor === 'black' ? 'white' : 'black'
        break
      }
    }
  }

  const addSwap = (swap: boolean) => {
    data.value.moves.push({ swap })

    updateHistory()
    updateInputMode()
  }

  const addOption10 = () => {
    data.value.moves.push({ option10: true, offerNumber: 10 })

    updateHistory()
    updateInputMode()
  }

  const addOfferNumber = (number: number) => {
    data.value.moves.push({ offerNumber: number })

    updateHistory()
    updateInputMode()
  }

  const addOfferMoveMark = (position: PositionProps) => {
    if (
      data.value.moves.some(move => move.x === position.x && move.y === position.y) ||
      data.value.moves[data.value.moves.length - 1]?.markStrings?.length === 0
    ) {
      return
    }

    const lastMove = data.value.moves[data.value.moves.length - 1]

    if (lastMove.offerMoveMarks?.some(offerMove => offerMove.x === position.x && offerMove.y === position.y)) {
      lastMove.offerMoveMarks = lastMove.offerMoveMarks.filter(offerMoveMark => offerMoveMark.x !== position.x || offerMoveMark.y !== position.y)
    } else {
      const offerNumberMoves = data.value.moves.filter(move => move.offerNumber)
      if (offerNumberMoves.length === 0) {
        return
      }
      const offerNumber = offerNumberMoves[offerNumberMoves.length - 1].offerNumber
      if (!lastMove.offerMoveMarks) {
        lastMove.offerMoveMarks = []
      }
      if (lastMove.offerMoveMarks?.length === offerNumber) {
        return
      }
      lastMove.offerMoveMarks?.push({
        x: position.x,
        y: position.y,
        color: data.value.moves.filter(move => move.number).length % 2 === 0 ? 'black' : 'white'
      })
    }
  }

  const removeOfferMoveMark = () => {
    const lastMove = data.value.moves[data.value.moves.length - 1]
    if (lastMove.offerMoveMarks === undefined) { return }
    lastMove.offerMoveMarks.pop()
  }

  const addOfferMoves = () => {
    const lastMove = data.value.moves[data.value.moves.length - 1]
    if (lastMove.offerMoveMarks === undefined) { return }

    data.value.moves.push({
      offerMoves: deepCopy(lastMove.offerMoveMarks)
    })

    updateHistory()
    updateInputMode()
  }

  const addMark = (position: PositionProps) => {
    if (
      data.value.moves.some(move => move.x === position.x && move.y === position.y) ||
      data.value.moves[data.value.moves.length - 1]?.markStrings?.length === 0
    ) {
      return
    }
    const move = data.value.moves[data.value.moves.length - 1]

    if (move?.marks?.some(mark => mark.x === position.x && mark.y === position.y)) {
      const foundedMark = move.marks.find(mark => mark.x === position.x && mark.y === position.y)
      if (!foundedMark) { return }
      move.markStrings = foundedMark.mark + move.markStrings
      move.marks = move.marks.filter(mark => mark.x !== position.x || mark.y !== position.y)
    } else {
      move?.marks?.push({
        x: position.x,
        y: position.y,
        mark: move?.markStrings?.at(0) || ''
      })
      move.markStrings = move?.markStrings?.slice(1)
    }
  }

  const removeMark = () => {
    const move = data.value.moves[data.value.moves.length - 1]
    const popMove = move?.marks?.pop()
    move.markStrings = popMove ? popMove.mark + move.markStrings : move.markStrings
  }

  const back = () => {
    if (
      data.value.type === 'game'
    ) {
      if (
        data.value.moves.length === 1 ||
        (data.value.branch.enable && data.value.moves[data.value.moves.length - 1].number === data.value.branch.startNumber)
      ) { return }
      data.value.moves.pop()
    } else if (data.value.type === 'puzzle' && data.value.mode === 'edit') {
      if (data.value.moves.length === 0) {
        return
      }
      data.value.moves.pop()
    } else if (data.value.type === 'puzzle' && data.value.mode === 'view') {
      if (data.value.moves.filter(move => move.number !== undefined && move.number !== 0).length === 0) { return }
      data.value.moves.pop()
    }

    updateInputMode()
  }

  const next = () => {
    if (data.value.moves.length === data.value.history.length) {
      return
    }

    data.value.moves = data.value.history.slice(0, data.value.moves.length + 1).map(move => ({ ...move }))

    updateInputMode()
  }

  const goToStart = () => {
    if (
      data.value.type === 'game' ||
      (data.value.type === 'puzzle' && data.value.mode === 'edit')
    ) {
      if (!data.value.branch.enable) {
        data.value.moves = data.value.moves.slice(0, 1)
      } else {
        data.value.moves = data.value.moves.filter(move =>
          move.number === undefined ||
          (move.number || 0) <= (data.value.branch.startNumber || 0)
        )
      }
    } else if (data.value.type === 'puzzle' && data.value.mode === 'view') {
      data.value.moves = data.value.moves.filter(move => move.number === undefined || move.number === 0)
    }

    updateInputMode()
  }

  const goToEnd = () => {
    data.value.moves = data.value.history.map(move => ({ ...move }))

    if (
      data.value.type === 'game' &&
      (data.value.inputMode !== 'stone' && data.value.inputMode !== 'mark')
    ) {
      updateInputMode()
    }
  }

  const updateInputMode = () => {
    if (data.value.type !== 'game') { return }
    const openingController = getOpeningRuleController(data.value.game.openingRule)
    const foundInputMode = openingController.getInputModeType(data.value.moves).find(inputModeType => inputModeType.select)?.inputMode
    if (foundInputMode) {
      data.value.inputMode = foundInputMode
    }
  }

  const updateHistory = () => {
    if (
      data.value.moves.length > data.value.history.length ||
      data.value.moves.some((move, index) => {
        return (
          move.x !== data.value.history[index].x ||
          move.y !== data.value.history[index].y ||
          move.color !== data.value.history[index].color ||
          move.number !== data.value.history[index].number ||
          move.swap !== data.value.history[index].swap ||
          move.offerNumber !== data.value.history[index].offerNumber
        )
      })
    ) {
      data.value.history = data.value.moves.map(move => ({ ...move }))
    }
  }

  const setMode = (mode: BoardMode) => {
    data.value.mode = mode

    switch (mode) {
      case 'edit':
        switch (data.value.type) {
          case 'game':
            data.value.inputMode = 'stone'
            updateInputMode()
            break
          case 'puzzle':
            data.value.inputMode = 'stone_alternate'
            data.value.moves = data.value.moves.filter(move => move.number === undefined)
            break
          default:
            break
        }
        break
      case 'view':
        switch (data.value.type) {
          case 'game':
            data.value.inputMode = 'stone'
            break
          case 'puzzle':
            data.value.inputMode = 'stone'
            data.value.moves.push({ number: 0, color: '-', marks: [], markStrings: defaultMarkStrings })
            if (data.value.puzzle.turn === '-') {
              const blackNumber = data.value.moves.filter(move => move.color === 'black').length
              const whiteNumber = data.value.moves.filter(move => move.color === 'white').length
              data.value.puzzle.turn = blackNumber > whiteNumber ? 'white' : 'black'
            }
            break
          default: break
        }
        break
      default:
        break
    }
  }

  const setType = (type: BoardType) => {
    if (data.value.type === type) { return }

    data.value.type = type

    switch (type) {
      case 'game':
        data.value.moves = [{ number: 0, color: '-', marks: [], markStrings: defaultMarkStrings }]
        data.value.history = [{ number: 0, color: '-', marks: [], markStrings: defaultMarkStrings }]
        data.value.inputMode = 'stone'
        break
      case 'puzzle':
        setBranchMode(false)
        data.value.moves = data.value.moves.slice(1, data.value.moves.length).map(move => ({
          ...move,
          number: undefined,
          marks: [],
          markStrings: defaultMarkStrings
        }))
        switch (data.value.mode) {
          case 'view':
            data.value.inputMode = 'stone'
            data.value.moves.push({ number: 0, color: '-', marks: [], markStrings: defaultMarkStrings })
            break
          case 'edit': data.value.inputMode = 'stone_alternate'; break
          default: break
        }
        break
      default:
        break
    }
  }

  const setBranchMode = (value: boolean) => {
    if (data.value.branch.enable === value) { return }
    if (value) {
      data.value.branch.startNumber = data.value.moves
        .filter(move => move.number !== undefined).length - 1
      data.value.branch.tempHistory = data.value.history.map(move => ({ ...move }))
    } else {
      const endIndex = data.value.moves.findIndex(move => move.number && move.number === data.value.branch.startNumber)
      data.value.moves = data.value.moves.slice(0, endIndex + 1)
      data.value.history = data.value.branch.tempHistory.map(move => ({ ...move }))
      data.value.branch.startNumber = 0
      updateInputMode()
    }
    data.value.branch.enable = value
  }

  const setOpeningRule = (openingRule: OpeningRule) => {
    if (data.value.game.openingRule === openingRule) { return }

    data.value.game.openingRule = openingRule
    data.value.moves = [{ number: 0, marks: [], markStrings: defaultMarkStrings }]
    data.value.history = [{ number: 0, marks: [], markStrings: defaultMarkStrings }]
    data.value.inputMode = 'stone'
  }

  const setNumberOfLine = (x: number, y:number) => {
    if (
      x < 5 || y < 5 ||
      x > 19 || y > 19 ||
      (data.value.board.numberOfXLine === x && data.value.board.numberOfYLine === y)
    ) {
      return
    }
    data.value.board.numberOfXLine = x
    data.value.board.numberOfYLine = y
    data.value.moves = []
    data.value.history = []

    const foundStarPositionProps = starPositionPropsList.find((starPositionProps) => {
      return starPositionProps.numberOfXLine === x && starPositionProps.numberOfYLine === y
    })
    data.value.board.starPositions = foundStarPositionProps ? foundStarPositionProps.starPositions : []
  }

  const updateScore = (boardState: Pick<BoardState, 'game' | 'moves'>, force: boolean = false) => {
    if (
      force ||
      data.value.moves.length === data.value.history.length ||
      !data.value.moves.every((move, index) =>
        move.color === boardState.moves[index].color &&
        move.x === boardState.moves[index].x &&
        move.y === boardState.moves[index].y
      )
    ) {
      data.value.moves = deepCopy(boardState.moves)
    }
    data.value.history = deepCopy(boardState.moves)
    data.value.game = deepCopy(boardState.game)
  }

  const reset = (boardState?: Partial<BoardState> | null) => {
    const newBoardState = boardState ? { ...deepCopy<BoardState>(defaultBoardState), ...boardState } : deepCopy<BoardState>(defaultBoardState)
    data.value.type = newBoardState.type
    data.value.mode = newBoardState.mode
    data.value.inputMode = newBoardState.inputMode
    data.value.alternateStoneColor = newBoardState.alternateStoneColor
    data.value.branch = newBoardState.branch
    data.value.game = newBoardState.game
    data.value.puzzle = newBoardState.puzzle
    data.value.moves = newBoardState.moves
    data.value.history = newBoardState.history
    data.value.board = newBoardState.board

    const foundStarPositionProps = starPositionPropsList.find((starPositionProps) => {
      return starPositionProps.numberOfXLine === newBoardState.board.numberOfXLine && starPositionProps.numberOfYLine === newBoardState.board.numberOfYLine
    })
    data.value.board.starPositions = foundStarPositionProps ? foundStarPositionProps.starPositions : []
  }

  const importBoardData = (boardData: string, site: RenjuSiteType): boolean => {
    const newBoardState = parseBoardData(boardData, site)
    if (!newBoardState) { return false }

    setType('game')
    setBranchMode(false)
    data.value.inputMode = 'stone'
    data.value.board.numberOfXLine = 15
    data.value.board.numberOfYLine = 15

    if (newBoardState.moves) {
      data.value.moves = newBoardState.moves
      data.value.history = deepCopy(newBoardState.moves)
    }
    if (newBoardState.game) {
      data.value.game = newBoardState.game
    }

    return true
  }

  const getUrlQuery = (history: boolean = false): string => {
    return makeBoardUrlQuery(data.value, '3', history)
  }

  const clearState = () => {
    clearNuxtState(key)
  }

  return {
    ...toRefs(data.value),
    addMove,
    addStone,
    addSwap,
    addOption10,
    addOfferNumber,
    addOfferMoveMark,
    removeOfferMoveMark,
    addOfferMoves,
    addMark,
    removeMark,
    back,
    next,
    goToEnd,
    goToStart,
    setMode,
    setType,
    setBranchMode,
    setOpeningRule,
    setNumberOfLine,
    updateScore,
    importBoardData,
    reset,
    getUrlQuery,
    clearState
  }
}

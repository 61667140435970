import { RouteQueryBoardV3 } from 'utils/urlBoard/urlBoardParserV3'
import { InputModeControlProps, OpeningRuleController } from '.'

export const swap1Controller: OpeningRuleController = {
  upperOfferNumber: 8,

  getGuideText (moves: MoveProps[]): null | string {
    switch (moves.length) {
      case 1:
      case 2:
      case 3:
        return '珠型提示'
      default:
        return null
    }
  },

  getInputModeType (moves: MoveProps[]): InputModeControlProps[] {
    switch (moves.length) {
      case 4:
        return [
          { inputMode: 'stone', disabled: true },
          { inputMode: 'swap', visible: false, select: true },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark', disabled: true }
        ]
      default:
        return [
          { inputMode: 'stone', select: true },
          { inputMode: 'swap', visible: false },
          { inputMode: 'offer_number', visible: false },
          { inputMode: 'offer_moves', visible: false },
          { inputMode: 'mark' }
        ]
    }
  },

  getBranchModeDisabled (moves: MoveProps[]): boolean {
    return moves.length <= 9
  },

  getCurrentPlayer (moves: MoveProps[]): PlayerType {
    switch (moves.length) {
      case 1:
      case 2:
        return 'first'
      case 3:
        return 'first'
      case 4:
        return 'second'
      default:
        return ((moves[4]?.swap ? 1 : 0) + moves.length) % 2 === 0 ? 'first' : 'second'
    }
  },

  validateMove (moves: MoveProps[], position: PositionProps): boolean {
    switch (moves.length) {
      case 1:
        return position.x === 7 && position.y === 7
      case 2:
        return position.x >= 6 && position.x <= 8 && position.y >= 6 && position.y <= 8
      case 3:
        return position.x >= 5 && position.x <= 9 && position.y >= 5 && position.y <= 9
      default:
        return true
    }
  },

  parseUrl (query: RouteQueryBoardV3, moves: MoveProps[]) {
    const { swp } = query

    if (swp && swp.length >= 1 && moves.length >= 4) {
      moves.splice(4, 0, { swap: swp[0] === '1' })
    }
  },

  getSwapYesLabel (_moves: MoveProps[]): string {
    return 'はい'
  },

  getSwapNoLabel (_moves: MoveProps[]): string {
    return 'いいえ'
  }
}

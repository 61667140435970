import { parseUrlBoardV2 } from './urlBoardParserV2'
import { parseUrlBoardV3 } from './urlBoardParserV3'
import { makeBoardUrlQueryV3 } from './urlBoardMakerV3'

export const boardTypePropsList: { param: string, value: BoardType }[] = [
  { param: 'g', value: 'game' },
  { param: 'p', value: 'puzzle' }
]

export const boardModePropsList: { param: string, value: BoardMode }[] = [
  { param: 'e', value: 'edit' },
  { param: 'v', value: 'view' }
]

export const boardTurnPropsList: { param: string, value: TurnType }[] = [
  { param: 'b', value: 'black' },
  { param: 'w', value: 'white' }
]

export const problemTypePropsList: { param: string, value: PuzzleType }[] = [
  { param: 'vct', value: 'vct' },
  { param: 'vcf', value: 'vcf' }
]

export const gameResultList: GameResult[] = ['-', 'resign', 'draw', 'five', 'timeout', 'forbidden']

export const problemTypeList: PuzzleType[] = ['vct', 'vcf']

export const problemTurnList: TurnType[] = ['black', 'white']

export const parseUrlBoard = (query: any): BoardState | null => {
  switch (query.v) {
    case '1': return parseUrlBoardV2(query)
    case '2': return parseUrlBoardV2(query)
    case '3': return parseUrlBoardV3(query)
    default: return parseUrlBoardV3(query)
  }
}

export const makeBoardUrlQuery = (boardState: BoardState, version: string, history: boolean = false): string => {
  switch (version) {
    case '3': return makeBoardUrlQueryV3(boardState, history)
    default: return ''
  }
}

import { starPositionPropsList } from '../defines'
import { boardModePropsList, boardTypePropsList, gameResultList, boardTurnPropsList, problemTypeList } from './index'

export interface RouteQueryBoardV3 {
  bxl: string,
  byl: string,
  typ: 'g' | 'p',
  mde: 'e' | 'v',
  brc: string,
  mvs: string,
  mks: string,
  par: string,
  pan: string,
  pae: string,
  pat: string,
  pbr: string,
  pbn: string,
  pbe: string,
  pbt: string,
  gtr: string,
  gtl: string,
  gdt: string,
  grd: string,
  gop: string,
  swp: string,
  ofn: string,
  ofm: string,
  gen: string,
  grs: string,
  pbs: string,
  pws: string,
  ptl: string,
  pty: 'vct' | 'vcf'
  ptr: 'b' | 'w'
}

export const parseUrlBoardV3 = (query: any): BoardState | null => {
  const { bxl, byl, typ, mde, brc, mvs, mks, gtr, gtl, gdt, grd, gop, par, pan, pae, pat, pbr, pbn, pbe, pbt, gen, grs, pbs, pws, ptl, pty, ptr } = query as RouteQueryBoardV3
  const boardState: BoardState = deepCopy<BoardState>(defaultBoardState)
  const moves: MoveProps[] = []

  if (bxl) { boardState.board.numberOfXLine = parseBXL(bxl) }
  if (byl) { boardState.board.numberOfYLine = parseBXL(byl) }
  if (typ) { boardState.type = boardTypePropsList.find(boardTypeProps => boardTypeProps.param === typ)?.value || 'game' }
  if (mde) { boardState.mode = boardModePropsList.find(boardModeProps => boardModeProps.param === mde)?.value || 'view' }

  if (pbs) { parsePXS(pbs, 'black', moves) }
  if (pws) { parsePXS(pws, 'white', moves) }
  if (boardState.type !== 'puzzle' || boardState.mode !== 'edit') { moves.push(emptyMoveProps) }
  if (mvs) { parseMVS(mvs, moves) }
  if (mks) { parseMKS(mks, moves) }
  if (brc) {
    boardState.branch.enable = true
    boardState.branch.startNumber = parseInt(brc, 10) || 0
    boardState.branch.tempHistory = boardState.moves.map(move => ({ ...move }))
  }

  let openingRuleController
  let foundInputMode

  switch (boardState.type) {
    case 'game':
      if (gtr) { boardState.game.tournament.renjuNetId = gtr }
      if (gtl) { boardState.game.title = decodeURIComponent(gtl) }
      if (gdt) { boardState.game.date = gdt }
      if (grd) { boardState.game.round = decodeURIComponent(grd) }
      if (gop) { boardState.game.openingRule = openingRulePropsList.find(openingRuleProps => openingRuleProps.param === gop)?.value || '-' }
      if (gen) { boardState.game.endNumber = parseGEN(gen) }
      if (grs) { boardState.game.result = gameResultList.find(gameResult => gameResult === grs) || '-' }
      if (par) { boardState.game.playerA.renjuNetId = par }
      if (pan) {
        boardState.game.playerA.name = decodeURIComponent(pan)
        boardState.game.playerA.color = 'black'
      }
      if (pae) { boardState.game.playerA.englishName = decodeURIComponent(pae) }
      if (pat) { boardState.game.playerA.remainTime = pat }
      if (pbr) { boardState.game.playerB.renjuNetId = pbr }
      if (pbn) {
        boardState.game.playerB.name = decodeURIComponent(pbn)
        boardState.game.playerB.color = 'white'
      }
      if (pbe) { boardState.game.playerB.englishName = decodeURIComponent(pbe) }
      if (pbt) { boardState.game.playerB.remainTime = pbt }
      openingRuleController = getOpeningRuleController(boardState.game.openingRule)
      openingRuleController.parseUrl(query, moves)
      foundInputMode = openingRuleController.getInputModeType(moves).find(inputModeType => inputModeType.select)?.inputMode
      if (foundInputMode) { boardState.inputMode = foundInputMode }
      break
    case 'puzzle':
      if (ptl) { boardState.puzzle.title = decodeURIComponent(ptl) }
      if (pty) { boardState.puzzle.type = problemTypeList.find(problemType => problemType === pty) || '-' }
      if (ptr) { boardState.puzzle.turn = boardTurnPropsList.find(boardTurnProps => boardTurnProps.param === ptr)?.value || '-' }
      switch (boardState.mode) {
        case 'view':
          boardState.inputMode = 'stone'
          if (!ptr) {
            boardState.puzzle.turn = moves.filter(move => move.color === 'black').length <= moves.filter(move => move.color === 'white').length ? 'black' : 'white'
          }
          break
        case 'edit':
          boardState.inputMode = 'stone_alternate'
          break
        default: break
      }
      Object.keys(query).forEach((key) => {
        const matchMvs = key.match(/^a(\d)*-mvs$/)
        if (matchMvs?.length === 2) {
          const answerMove: MoveProps[] = []
          parseMVS(query[key], answerMove)
          if (`a${matchMvs[1]}-mks` in query) { parseMKS(query[`a${matchMvs[1]}-mks`], answerMove) }
          boardState.answers.push({
            title: query[`a${matchMvs[1]}-title`] || '答え',
            moves: answerMove
          })
        }
      })
      break
    default:
      break
  }

  boardState.board.starPositions = starPositionPropsList.find(starPositionProps =>
    starPositionProps.numberOfXLine === boardState.board.numberOfXLine &&
    starPositionProps.numberOfYLine === boardState.board.numberOfYLine
  )?.starPositions || []

  boardState.moves = moves
  boardState.history = boardState.moves.map(move => ({ ...move }))

  return boardState
}

const parseBXL = (bxl: string): number => {
  const parsedLineNumber = parseInt(bxl, 10)
  return (!parsedLineNumber || parsedLineNumber > 19 || parsedLineNumber < 5) ? 15 : parsedLineNumber
}

const parseMVS = (mvs: string, moves: MoveProps[]) => {
  const positionStrings = mvs.match(/.{2}/g)

  if (positionStrings === null) { return [] }

  positionStrings.forEach((positionString, index) => {
    const parsedX = parseInt(positionString[0], 36)
    const parsedY = parseInt(positionString[1], 36)
    if (
      isNaN(parsedX) || isNaN(parsedY) ||
      parsedX < 0 || parsedX > 19 ||
      parsedY < 0 || parsedY > 19
    ) { return }
    moves.push({
      x: parsedX,
      y: parsedY,
      color: moves.filter(move => move.number !== undefined).length % 2 === 0 ? 'white' : 'black',
      number: index + 1,
      marks: [],
      markStrings: defaultMarkStrings
    })
  })
}

const parseMKS = (mks: string, moves: MoveProps[]) => {
  const decodedMarkString = decodeURIComponent(mks)
  const splitMarkStrings = decodedMarkString.split('-')
  if (splitMarkStrings.length === 0) { return }
  splitMarkStrings.forEach((markString) => {
    if (markString.length < 4) { return }
    const number = parseInt(markString.slice(0, 2), 36)
    const marks = markString.slice(2, markString.length).match(/.{3}/g)
    const foundMove = moves.find(move => move.number === number)
    if (foundMove === undefined) { return }
    marks?.forEach((mark) => {
      const parsedX = parseInt(mark[0], 36)
      const parsedY = parseInt(mark[1], 36)
      if (
        isNaN(parsedX) || isNaN(parsedY) ||
        parsedX < 0 || parsedX > 19 ||
        parsedY < 0 || parsedY > 19
      ) { return }
      foundMove?.marks?.push({
        x: parsedX,
        y: parsedY,
        mark: mark[2]
      })
    })
  })
}

const parsePXS = (pxs: string, color: StoneColor, moves: MoveProps[]) => {
  const stoneStrings = pxs.match(/.{2}/g)
  if (stoneStrings === null) { return }
  stoneStrings.forEach((stoneStrings) => {
    const parsedX = parseInt(stoneStrings[0], 36)
    const parsedY = parseInt(stoneStrings[1], 36)
    if (
      isNaN(parsedX) || isNaN(parsedY) ||
      parsedX < 0 || parsedX > 19 ||
      parsedY < 0 || parsedY > 19
    ) { return }
    const foundMove = moves.find(move => move.x === parsedX && move.y === parsedY)
    if (foundMove) { return }
    moves.push({
      x: parsedX,
      y: parsedY,
      color,
      marks: [],
      markStrings: defaultMarkStrings
    })
  })
}

const parseGEN = (gen: string) => {
  const splitGameEndNumber = gen.split('-')
  if (
    splitGameEndNumber.length !== 2 ||
    (
      splitGameEndNumber[0] !== 'black' &&
      splitGameEndNumber[0] !== 'white'
    ) ||
    isNaN(parseInt(splitGameEndNumber[1], 10))
  ) { return '-' }

  return gen
}
